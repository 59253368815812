<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <br>

            <el-form
                    label-width="80px"
                    :model="result"
                    ref="searchForm"
            >
                <h3>{{result.note}}</h3>
                <br>
                <el-row :gutter="20">
                    <el-col :span="24" v-for="(item,index) in result.configValue" :key="item.id">
                        <el-card>
                            <div slot="header" class="clearfix">
                                <el-button type="danger" size="small" @click.prevent="removeDomain(item)">删除</el-button>
                            </div>
                            <el-form-item :prop="'configValue.' + index + '.amount'" label="充值金额" :rules="[
                                  { required: true, message: '请输入充值金额', trigger: 'blur' }
                                  ]">
                                <el-input v-model="item.amount" clearable placeholder="请输入配置名称" class="inner-input">
                                </el-input>
                            </el-form-item>
                            <el-form-item :prop="'configValue.' + index + '.type'" label="赠送方式">
                                <el-radio-group v-model="item.type">
                                    <el-radio :label="1">定值</el-radio>
                                    <el-radio :label="2">百分比</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item :prop="'configValue.' + index + '.rate'" label="赠送数值" :rules="[
                                  { required: true, message: '请输入赠送数值', trigger: 'blur' }
                                  ]">
                                <el-input v-model="item.rate" clearable placeholder="请输入配置名称"
                                          class="inner-input"></el-input>

                            </el-form-item>

                        </el-card>
                        <br>
                    </el-col>

                </el-row>

                <el-form-item label-width="0">
                    <el-button type="primary" @click="handleAddConfig">
                        增加配置
                    </el-button>
                    <el-button type="primary" @click="handleEdit">
                        保存
                    </el-button>
                    <el-button type="primary" plain @click="handdleCanecl">
                        取消
                    </el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </el-container>
</template>

<script>

import _ from 'lodash'
import request from '@/utils/request'
import {uuid} from "@/utils";

export default {
    name: "rechargeManage",

    data() {

        return {
            result: {}
        };
    },
    methods: {
        removeDomain(item) {
            var index = this.result.configValue.indexOf(item)
            if (index !== -1) {
                this.result.configValue.splice(index, 1)
            }
        },
        handleAddConfig() {
            let config = {
                id: uuid(),
                amount: '',
                rate: '',
                type: 1
            }
            this.result.configValue.push(config)
        },
        async handleEdit() {
            try {

                this.$refs.searchForm.validate(async valid=>{
                    if(valid){
                        await this.$confirm('确定保存配置?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'error'})
                        let formdata = _.cloneDeep(this.result)
                        formdata.configValue = JSON.stringify(formdata.configValue)
                        formdata.type = 2
                        let {data} = await request({
                            url: '/platform/sys/conf/accountConfig',
                            method: 'post',
                            data: formdata
                        })
                        this.$message.success('更新成功')
                        this.getData()
                    }
                })

            } catch (e) {

            }
        },
        async handdleCanecl() {
            try {
                await this.$confirm('确定取消修改?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'error'})
                this.getData()
            } catch (e) {

            }
        },
        async getData(page) {
            try {

                let {data} = await request({
                    url: '/platform/sys/conf/accountConfig',
                    method: 'post',
                    data: {
                        type: 1
                    }
                })
                data.configValue = JSON.parse(data.configValue)
                this.result = data
                console.log(data)
            } catch (e) {
                console.error(e)
            }
        },

    },
    mounted() {
        this.getData()
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding-top: 0;
}
</style>
